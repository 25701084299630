var render = function render(){var _vm=this,_c=_vm._self._c;return _c('df-modal',{attrs:{"data-id":"modal-execute-sowing","persistent":"","action-name":_vm.actionName,"loading":_vm.loadingFormAPI,"title":_vm.modalTitle},on:{"action-click":_vm.actionMethod,"close":_vm.closeExecuteSowingModal},scopedSlots:_vm._u([{key:"card-content",fn:function(){return [_c('div',{staticClass:"df-flex-xl df-flex-col execute-sowing-modal"},[(!_vm.editing)?_c('div',{staticClass:"df-flex-sm modal__warning"},[_c('font-awesome-icon',{attrs:{"color":"#4A76BC","icon":"exclamation-circle"}}),_c('p',[_vm._v(_vm._s(_vm.$t('SowingsPlanning.execute_season.warning')))])],1):_vm._e(),_c('sowing-form',{ref:"sowingForm",attrs:{"sowing-id":_vm.currentSowingId},on:{"crop-selected":_vm.setCurrentCropId,"fill-input-fields":_vm.fillMandatoryFields},scopedSlots:_vm._u([{key:"sowing-info",fn:function(){return [_c('div',{staticClass:"df-flex-l"},[_c('div',{staticClass:"df-col-6 df-col-12-t df-col-12-m"},[_c('df-input',{attrs:{"suffix":"ha","type":"number","label":_vm.$t('SowingsPlanning.execute_season.sown_area'),"placeholder":_vm.$t('SowingsPlanning.execute_season.insert_area'),"rules":[
                  _vm.maxValue(
                    _vm.sownArea,
                    _vm.fieldTotalArea,
                    _vm.$t('SowingsPlanning.exceptions.over_field_max_area')
                  ),
                  _vm.required,
                ]},model:{value:(_vm.sownArea),callback:function ($$v) {_vm.sownArea=$$v},expression:"sownArea"}})],1),_c('div',{staticClass:"df-col-6 df-col-12-t df-col-12-m"},[_c('df-date-picker',{attrs:{"disabled":_vm.disableSowingDate,"exception-dates":_vm.notRecommendedDates,"hint":_vm.$t(
                    'SowingsPlanning.date_picker_component.estimated_sowing_hint'
                  ),"label":_vm.$t('SowingsPlanning.execute_season.sowing_date'),"max":_vm.currentSeasonCrop.end_period,"min":_vm.currentSeasonCrop.start_period,"placeholder":_vm.$t('SowingsPlanning.date_picker_component.select_date'),"rules":[_vm.required]},model:{value:(_vm.sowingDate),callback:function ($$v) {_vm.sowingDate=$$v},expression:"sowingDate"}})],1)])]},proxy:true},{key:"secondary-sowing-date",fn:function(){return [_c('df-date-picker',{attrs:{"disabled":_vm.disableEmergenceSowingDate,"hint":_vm.$t(
                'SowingsPlanning.date_picker_component.estimated_sowing_hint'
              ),"label":_vm.$t('SowingsPlanning.execute_season.emergency_sowing_date'),"max":_vm.currentSeasonCrop.end_period,"min":_vm.currentSeasonCrop.start_period,"placeholder":_vm.$t('SowingsPlanning.date_picker_component.select_date'),"rules":[_vm.required]},model:{value:(_vm.emergenceSowingDate),callback:function ($$v) {_vm.emergenceSowingDate=$$v},expression:"emergenceSowingDate"}})]},proxy:true}])}),_c('sowing-complementary-data',{ref:"sowingComplementaryData",attrs:{"task-extra-info":_vm.taskExtraInfo}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }