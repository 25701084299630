<template>
  <df-modal
    data-id="modal-execute-sowing"
    persistent
    :action-name="actionName"
    :loading="loadingFormAPI"
    :title="modalTitle"
    @action-click="actionMethod"
    @close="closeExecuteSowingModal"
  >
    <template #card-content>
      <div class="df-flex-xl df-flex-col execute-sowing-modal">
        <div v-if="!editing" class="df-flex-sm modal__warning">
          <font-awesome-icon color="#4A76BC" icon="exclamation-circle" />
          <p>{{ $t('SowingsPlanning.execute_season.warning') }}</p>
        </div>
        <sowing-form
          ref="sowingForm"
          :sowing-id="currentSowingId"
          @crop-selected="setCurrentCropId"
          @fill-input-fields="fillMandatoryFields"
        >
          <template #sowing-info>
            <div class="df-flex-l">
              <div class="df-col-6 df-col-12-t df-col-12-m">
                <df-input
                  v-model="sownArea"
                  suffix="ha"
                  type="number"
                  :label="$t('SowingsPlanning.execute_season.sown_area')"
                  :placeholder="
                    $t('SowingsPlanning.execute_season.insert_area')
                  "
                  :rules="[
                    maxValue(
                      sownArea,
                      fieldTotalArea,
                      $t('SowingsPlanning.exceptions.over_field_max_area')
                    ),
                    required,
                  ]"
                />
              </div>
              <div class="df-col-6 df-col-12-t df-col-12-m">
                <df-date-picker
                  v-model="sowingDate"
                  :disabled="disableSowingDate"
                  :exception-dates="notRecommendedDates"
                  :hint="
                    $t(
                      'SowingsPlanning.date_picker_component.estimated_sowing_hint'
                    )
                  "
                  :label="$t('SowingsPlanning.execute_season.sowing_date')"
                  :max="currentSeasonCrop.end_period"
                  :min="currentSeasonCrop.start_period"
                  :placeholder="
                    $t('SowingsPlanning.date_picker_component.select_date')
                  "
                  :rules="[required]"
                />
              </div>
            </div>
          </template>
          <template #secondary-sowing-date>
            <df-date-picker
              v-model="emergenceSowingDate"
              :disabled="disableEmergenceSowingDate"
              :hint="
                $t(
                  'SowingsPlanning.date_picker_component.estimated_sowing_hint'
                )
              "
              :label="
                $t('SowingsPlanning.execute_season.emergency_sowing_date')
              "
              :max="currentSeasonCrop.end_period"
              :min="currentSeasonCrop.start_period"
              :placeholder="
                $t('SowingsPlanning.date_picker_component.select_date')
              "
              :rules="[required]"
            />
          </template>
        </sowing-form>
        <sowing-complementary-data
          ref="sowingComplementaryData"
          :task-extra-info="taskExtraInfo"
        />
      </div>
    </template>
  </df-modal>
</template>

<script>
import DfDatePicker from '@/lib/components/DatePicker/DfDatePicker.vue'
import DfInput from '@/lib/components/Input/DfInput.vue'
import DfModal from '@/lib/Modal/DfModal.vue'
import SowingComplementaryData from '@/modules/sowingsList/components/modal/SowingComplementaryData.vue'
import sowingsListService from '@/modules/sowingsList/services/sowingsListService.js'
import SowingForm from '@/modules/sowingsPlanning/components/SowingForm.vue'
import sowingsPlanningService from '@/modules/sowingsPlanning/services/sowingsPlanningService.js'
import sowingViewService from '@/modules/sowingView/services/sowingViewService.js'
import { maxValue, required } from '@/utils/formRules'
import { logEvent, events } from '@/services/analytics'
import { mapGetters } from 'vuex'
import { SOYBEAN_ID, WHEAT_ID } from '@/utils/crops'

export default {
  name: 'ExecuteSowingModal',

  components: {
    DfDatePicker,
    DfInput,
    DfModal,
    SowingComplementaryData,
    SowingForm,
  },

  props: {
    editing: {
      default: false,
      type: Boolean,
    },
    sowingId: {
      default: null,
      type: Number,
    },
  },

  data() {
    return {
      createdSowingId: null,
      currentCropId: null,
      emergenceSowingDate: '',
      hasAnyRegisteredSpraying: false,
      loadingFormAPI: false,
      maxValue,
      required,
      sowingDate: '',
      sownArea: null,
      taskExtraInfo: {},
    }
  },

  inject: {
    hasRegisteredSpraying: {
      default: () => () => {},
      from: 'hasRegisteredSpraying',
    },
    requestFieldSowingsList: {
      default: () => () => {},
      from: 'requestFieldSowingsList',
    },
    requestSowingInfos: {
      default: () => () => {},
      from: 'requestSowingInfos',
    },
  },

  async created() {
    if (this.editing && this.hasRegisteredSpraying) {
      const params = {
        sowing_ids: [this.sowingId],
        spraying_status: 'all',
      }
      try {
        const { data } = await sowingViewService.getSowingSprayings(params)
        this.hasAnyRegisteredSpraying = data.some(
          (spraying) => spraying.applied_date
        )
      } catch (error) {
        console.error(error)
      }
    }
  },

  computed: {
    ...mapGetters('farms', ['isFarmFromUSA']),
    ...mapGetters('seasonsCrops', ['currentSeasonCrop']),
    actionMethod() {
      return this.editing ? this.saveEdit : this.executeSowing
    },
    actionName() {
      return this.editing
        ? this.$t('SowingsList.execute_sowing_modal.save')
        : this.$t('SowingsPlanning.execute_season.execute')
    },
    currentSowingId() {
      return this.sowingId || this.createdSowingId
    },
    disableEmergenceSowingDate() {
      return (
        this.editing &&
        (this.hasRegisteredSpraying() || this.hasAnyRegisteredSpraying)
      )
    },
    disableSowingDate() {
      return (
        !this.currentCropId ||
        (this.editing &&
          (this.hasRegisteredSpraying() || this.hasAnyRegisteredSpraying))
      )
    },
    fieldTotalArea() {
      // CM: validação a ser feita depois
      return 100
    },
    modalTitle() {
      return this.editing
        ? this.$t('SowingsList.execute_sowing_modal.edit_sowing')
        : this.$t('SowingsPlanning.execute_season.title')
    },
    notRecommendedDates() {
      if (this.currentCropId) {
        const isSoybean = this.currentCropId == SOYBEAN_ID
        const isWheat = this.currentCropId == WHEAT_ID
        // CM: validação a ser feita depois
        // if (!isSoybean || !this.cultivar.recommended) return false
        // CM: essa informação vem da cultivar, ver com Ramon como vamos trazer
        if (isSoybean && this.isFarmFromUSA) return []
        else if (isSoybean && !this.isFarmFromUSA) return []
        else if (isWheat && this.isFarmFromUSA) return []
        else if (isWheat && !this.isFarmFromUSA) return []
        else return []
      }
      return []
    },
  },

  methods: {
    closeExecuteSowingModal() {
      this.$emit('close')
    },
    async executeSowing() {
      const sowingFormComponent = this.$refs.sowingForm
      const vForm = sowingFormComponent.$refs.form
      if (!vForm.validate()) return
      logEvent(
        events.sowingsPlanningModule.SowingsPlanning.execute_sowing_season
      )
      this.loadingFormAPI = true
      const complementaryData = this.$refs.sowingComplementaryData
      const params = {
        task: {
          type: 'sowing',
          estimated_start_date: null,
          estimated_completed_date: null,
          started_at: this.sowingDate,
          completed_at: this.sowingDate,
          account_id: sowingFormComponent.currentUser.account.id,
          created_by: sowingFormComponent.currentUser.id,
          is_planning: false,
          extra_info: {
            variety_name: sowingFormComponent.genetic,
            asset_owner_name: sowingFormComponent.companyName,
            emergence_date: this.emergenceSowingDate,
            cycle: sowingFormComponent.cycle,
            origin: complementaryData.origin,
            sown_quantity: complementaryData.sownQuantity,
            seeder_system_id: complementaryData.seederSystem.value,
            seed_class_id: complementaryData.selectedSeedClass.value,
            germinative_power: complementaryData.germinativePower,
            seed_vigor: complementaryData.seedVigor,
            line_spacing: complementaryData.lineSpacing,
            population_lines: complementaryData.populationLines,
            operating_speed: complementaryData.operatingSpeed,
            seed_treatment: complementaryData.seedTreatment,
            inoculant: complementaryData.inoculant,
            harvested: complementaryData.harvested,
            harvest_quantity: complementaryData.harvestedQuantity,
          },
        },
        crop_zone: {
          crop_id: sowingFormComponent.selectedCrop.value,
          variety_id: sowingFormComponent.selectedGenetic.value,
          farm_id: sowingFormComponent.farmId,
          field_id: sowingFormComponent.selectedFields[0].value,
          area: this.sownArea,
        },
      }
      if (this.sowingId) {
        params.sowing_id = this.sowingId
        try {
          await sowingsPlanningService.executePlanningSowing(params)
          this.$root.$emit(
            'notify',
            'success',
            this.$t('SowingsPlanning.execute_season.successful_request_title'),
            this.$t('SowingsPlanning.execute_season.successful_request_text')
          )
          this.redirectToSowing()
        } catch (error) {
          console.error(error)
        } finally {
          this.loadingFormAPI = false
        }
      } else {
        try {
          this.createdSowingId = await sowingsListService.createExecutedSowing(
            params
          )
          this.$root.$emit(
            'notify',
            'success',
            this.$t('SowingsPlanning.execute_season.successful_request_title'),
            this.$t('SowingsPlanning.execute_season.successful_request_text')
          )
          this.redirectToSowing()
        } catch (error) {
          console.error(error)
        } finally {
          this.loadingFormAPI = false
        }
      }
    },
    fillMandatoryFields(sowing) {
      this.taskExtraInfo = sowing.data.task.extra_info
      this.sownArea = this.taskExtraInfo.sown_area?.toFixed(2)
      this.sowingDate = this.taskExtraInfo.date
      this.emergenceSowingDate = this.taskExtraInfo.emergence_date
    },
    redirectToSowing() {
      this.$router.push({
        path: '/sowing',
        query: {
          sowing_id: this.currentSowingId,
        },
      })
    },
    async saveEdit() {
      const sowingFormComponent = this.$refs.sowingForm
      const vForm = sowingFormComponent.$refs.form
      if (!vForm.validate()) return
      this.loadingFormAPI = true
      const complementaryData = this.$refs.complementaryData
      const params = {
        sowing_id: sowingFormComponent.sowingId,
        task: {
          type: 'sowing',
          estimated_start_date: null,
          estimated_completed_date: null,
          started_at: this.sowingDate,
          completed_at: this.sowingDate,
          account_id: sowingFormComponent.currentUser.account.id,
          created_by: sowingFormComponent.currentUser.id,
          is_planning: false,
          extra_info: {
            variety_name: sowingFormComponent.genetic,
            asset_owner_name: sowingFormComponent.companyName,
            emergence_date: this.emergenceSowingDate,
            cycle: sowingFormComponent.cycle,
            origin: complementaryData.origin,
            sown_quantity: complementaryData.sownQuantity,
            seeder_system_id: complementaryData.seederSystem.value,
            seed_class_id: complementaryData.selectedSeedClass.value,
            germinative_power: complementaryData.germinativePower,
            seed_vigor: complementaryData.seedVigor,
            line_spacing: complementaryData.lineSpacing,
            population_lines: complementaryData.populationLines,
            operating_speed: complementaryData.operatingSpeed,
            seed_treatment: complementaryData.seedTreatment,
            inoculant: complementaryData.inoculant,
            harvested: complementaryData.harvested,
            harvest_quantity: complementaryData.harvestedQuantity,
          },
        },
        crop_zone: {
          crop_id: sowingFormComponent.selectedCrop.value,
          variety_id: sowingFormComponent.selectedGenetic.id,
          farm_id: sowingFormComponent.farmId,
          field_id: sowingFormComponent.selectedFields[0].value,
          area: this.sownArea,
        },
      }
      try {
        await sowingsListService.editSowing(params)
        this.$root.$emit(
          'notify',
          'success',
          this.$t('SowingsPlanning.execute_season.saved_changes')
        )
        if (this.requestFieldSowingsList) this.requestFieldSowingsList()
        else this.requestSowingInfos()
        this.closeExecuteSowingModal()
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingFormAPI = false
      }
    },
    setCurrentCropId(value) {
      this.currentCropId = value
    },
  },
}
</script>

<style lang="scss" scoped>
.execute-sowing-modal {
  .modal__warning {
    padding: 16px;
    background-color: #f2f6ff;
    border: 1px solid #cddbff;
    border-radius: 4px;
    flex-wrap: nowrap;

    p {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
}
</style>
